import React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 90 90"
      className="c-icon c-icon--strategy"
      preserveAspectRatio="xMinYMid meet"
    >
      <circle cx="45" cy="60" r="13" />
      <circle cx="45" cy="52" r="28" />
      <circle cx="45" cy="45" r="43" />
    </svg>
  )
}
